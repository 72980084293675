import React from "react";
import { graphql } from 'gatsby'
import { Link } from "gatsby";
import ReactMarkdown from "react-markdown";
import { createHubSpotForm, resetWebflow } from "../utils/common";
import { HelmetDatoCms } from "gatsby-source-datocms";
import Notification from "../components/header/notification";
import Layout from "../layouts";

export default ({
  data: {
    datoCmsWebinarDetail: {
      id,
      webinarTitle,
      webinarDescription,
      formHeading,
      hubspotFormId,
      hubspotFormRedirect,
      goToWebinarKey,
      seoMetaTags,
      notificationPage
    },
    allDatoCmsNotification: { edges: notifications }
  }
}) => {
  resetWebflow("5bb0a6341270ad6783e211c3");
  createHubSpotForm({
    formId: hubspotFormId,
    targetId: "#webinarDetailHubspotForm",
    redirectUrl: hubspotFormRedirect,
    goToWebinarWebinarKey: goToWebinarKey
  });
  return (
    <Layout>
      <HelmetDatoCms seo={seoMetaTags} />
      <Notification
        notifications={notifications}
        linkedPage={notificationPage}
      />
      <div className="navbar">
        <div className="container grid valign landing">
          <Link to="/" className="logo-nav w-inline-block" />
        </div>
      </div>
      <header className="white-section event">
        <div className="container grid">
          <div className="_2cols-box">
            <h1 className="h-nofixedwidth">{webinarTitle}</h1>
            <div className="sm-spacing" />
            <div>
              <ReactMarkdown source={webinarDescription} escapeHtml={false} />
            </div>
          </div>
          <div className="webinar-form-container">
            <h4 className="h4">{formHeading}</h4>
            <div className="sm-spacing" />
            <div className="w-embed w-script">
              <div id="webinarDetailHubspotForm" />
            </div>
          </div>
        </div>
      </header>
    </Layout>
  );
};

export const query = graphql`
  query WebinarDetailQuery($slug: String!) {
    datoCmsWebinarDetail(slug: { eq: $slug }) {
      id
      webinarTitle
      webinarDescription
      formHeading
      hubspotFormId
      hubspotFormRedirect
      goToWebinarKey
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      notificationPage {
        id
        page
      }
    }
    allDatoCmsNotification {
        edges {
          node {
            id
            content
            show
            weeks
            notificationPosition
            linkToPage {
              id
              page
            }
            backgroundColor {
              hex
            }
          }
        }
      }
  }
`;
